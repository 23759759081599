/**@file: 路由文件 */
import Vue from 'vue';
import Router from 'vue-router';
import VueRouter from 'vue-router';

Vue.use(Router);

export const constantRoutes = [{
		path: '/',
		name: 'Home',
		component: () => import('@/views/Home'),
	}, {
		path: '/login',
		name: 'Login',
		component: () => import('@/views/Login'),
	},
	{
		path: '/404',
		component: () => import('@/views/404'),
	},
	// 销售工具
	{
		path: '/sales_tools',
		redirect: '/sales_tools/statistic',
		component: () => import('@/views/Home'),
		children: [{
			path: 'statistic',
			name: 'CustomerStatistic',
			meta: {
				title: '客户统计',
			},
			component: () => import('@/views/statistic/Index.vue')
		}, ]
	}
];

export const asyncRoutes = [{
		path: '/',
		redirect: '/index',
		component: () => import('@/views/Home'),
		children: [{
			path: 'index',
			name: 'Index',
			meta: {
				title: '首页',
			},
			component: () => import('@/views/Index/Index'),
		}, ]
	},
	// 获客工具
	{
		path: '/guest_tools',
		redirect: '/guest_tools/live_code',
		component: () => import('@/views/Home'),
		children: [{
				path: 'live_code',
				name: 'GuestLiveCode',
				meta: {
					title: '渠道活码',
				},
				component: () => import('@/views/LiveCode/CodeManage.vue'),
			},
			{
				path: 'channel-code/create',
				meta: {
					title: '创建活码',
					role: '/guest_tools/live_code', // 动态过滤时保留的路由
				},
				component: () => import('@/views/ChannelCode/create.vue')
			},
			{
				path: 'channel-code/edit',
				meta: {
					title: '编辑活码',
					role: '/guest_tools/live_code', // 动态过滤时保留的路由
					isEdit: true,
				},
				component: () => import('@/views/ChannelCode/create.vue')
			},
			{
				path: 'article',
				name: 'GuestArticle',
				meta: {
					title: '文章引流',
				},
				component: () => import('@/views/GuestTools/ArticleList'),
			}, {
				path: 'activity',
				name: 'GuestActivity',
				meta: {
					title: '活动表单',

				},
				component: () => import('@/views/Activity/List'),
			},
			{
				path: 'activity/:id',
				name: 'AddActivity',
				meta: {
					role: '/guest_tools/activity'
				},
				component: () => import('@/views/Activity/Add'),
			},
			{
				path: 'fission',
				name: 'GuestFission',
				meta: {
					title: '活动裂变',
				},
				component: () => import('@/views/Fission/Index'),
			},
			{
				path: 'fission/:id',
				name: 'oFission',
				meta: {
					role: '/guest_tools/fission'
				},
				component: () => import('@/views/Fission/Operate'),
			},
			{
				path: 'invitation',
				name: 'GuestInvitation',
				meta: {
					title: '邀请有奖',
				},
				component: () => import('@/views/Invitation/Index'),
			},
			{
				path: 'invitation/:id',
				name: 'inCreate',
				meta: {
					role: '/guest_tools/invitation'
				},
				component: () => import('@/views/Invitation/Create'),
			},
			{
				path: 'luck-draw',
				meta: {
					title: '抽奖助手'
				},
				component: () => import('@/views/LuckDraw/index.vue')
			},
			{
				path: 'luck-draw/edit',
				meta: {
					title: '编辑活动',
					isEdit: true,
					role: '/guest_tools/luck-draw'
				},
				component: () => import('@/views/LuckDraw/form.vue')
			},
			{
				path: 'luck-draw/create',
				meta: {
					title: '创建活动',
					role: '/guest_tools/luck-draw'
				},
				component: () => import('@/views/LuckDraw/form.vue')
			},
		]
	},
	// 客户CRM
	{
		path: '/custom_crm',
		redirect: '/custom_crm/manage',
		component: () => import('@/views/Home'),
		children: [{
				path: 'manage',
				name: 'customManage',
				meta: {
					title: '客户',
				},
				component: () => import('@/views/CustomCrm/CustomManage'),
			},
			{
				path: 'label',
				name: 'customLabel',
				meta: {
					title: '客户标签',
				},
				component: () => import('@/views/CustomCrm/CustomLabel'),
			},
			{
				path: 'base',
				name: 'customBase',
				meta: {
					title: '客户群',
				},
				component: () => import('@/views/CustomCrm/CustomBase'),
			},
			{
				path: 'high_seas',
				name: 'HighSeas',
				meta: {
					title: '客户公海',
				},
				component: () => import('@/views/highSeas/index.vue')
			},
			{
				path: 'rules',
				name: 'Rules',
				meta: {
					title: '客户规则管理'
				},
				component: () => import('@/views/rules/index.vue')
			},
			{
				path: 'business',
				name: 'Business',
				meta: {
					title: '业务字段管理',
				},
				component: () => import('@/views/business/index.vue')
			},
			{
				path: 'sop',
				meta: {
					title: '客户sop',
				},
				component: () => import('@/views/sop/index.vue'),
			},
			{
				path: 'sop/:id',
				meta: {
					role: '/custom_crm/sop'
				},
				component: () => import('@/views/sop/edit.vue'),
			},
			{
				path: 'clue',
				name: 'CustomClue',
				meta: {
					title: '线索管理',
				},
				component: () => import('@/views/Clue/Index.vue'),
			}
		]
	},
	// 销售工具
	{
		path: '/sales_tools',
		redirect: '/sales_tools/manage',
		component: () => import('@/views/Home'),
		children: [{
				path: 'manage',
				name: 'ContentManage',
				meta: {
					title: '聊天工具栏',
					keepAlive: true, // 需要被缓存
				},
				component: () => import('@/views/Content/ContentManage')
			},
			{
				path: 'manage/addgraphic',
				name: 'AddGraphic',
				meta: {
					title: '添加图文',
					role: '/sales_tools/manage'
				},
				component: () => import('@/views/Content/AddGraphic'),
			},
			{
				path: 'in_group',
				name: 'inGroup',
				meta: {
					title: '自动入群',
				},
				component: () => import('@/views/CustomCrm/InGroup'),
			},
			{
				path: 'in_group/create',
				name: 'newQrcode',
				meta: {
					title: '新建入群码',
					role: '/sales_tools/in_group'
				},
				component: () => import('@/views/CustomCrm/NewQrcode'),
			},
			{
				path: 'clock',
				name: 'Clock',
				meta: {
					title: '群打卡',
				},
				component: () => import('@/views/LiveCode/Clock'),
			},
			{
				path: 'layered',
				name: 'Layered',
				meta: {
					title: '客户分层',
				},
				component: () => import('@/views/layered/Index.vue'),
			},
		]
	},
	{ // 标签拉群
		path: '/pull_group',
		redirect: '/pull_group/index',
		component: () => import('@/views/Home'),
		children: [{
				path: 'index',
				name: 'PullGroupIndex',
				meta: {
					title: '标签拉群',
				},
				component: () => import('@/views/LabelLaGroup/List'),
			},
			{
				path: 'create',
				name: 'PullGroupCreate',
				meta: {
					role: '/pull_group/index'
				},
				component: () => import('@/views/LabelLaGroup/Create'),
			},
		]
	},
	// 企业管理
	{
		path: '/company_manage',
		redirect: '/company_manage/staff',
		component: () => import('@/views/Home'),
		children: [{
				path: 'staff',
				name: 'CompanyStaff',
				meta: {
					title: '员工管理',
				},
				component: () => import('@/views/AddressBook/Member'),
			},
			{
				path: 'label',
				name: 'CompanyLabel',
				meta: {
					title: '企业标签',
				},
				component: () => import('@/views/AddressBook/Label'),
			},
			{
				path: 'plan',
				name: 'CompanyPlan',
				meta: {
					title: '拓客计划',
				},
				component: () => import('@/views/Expand/Index'),
			},
			{
				path: 'dimission',
				name: 'Dimission',
				meta: {
					title: '离职继承'
				},
				component: () => import('@/views/AddressBook/dimission.vue')
			},
			{
				path: 'delete',
				name: 'Delete',
				meta: {
					title: '删人管理'
				},
				component: () => import('@/views/AddressBook/delete.vue')
			}
		]
	},
	// 子账户管理
	{
		path: '/account',
		redirect: '/account/index',
		component: () => import('@/views/Home'),
		children: [{
			path: 'index',
			name: 'AccountIndex',
			meta: {
				title: '子账号管理',
			},
			component: () => import('@/views/Account'),
		}, ]
	},
	// 企业配置
	{
		path: '/company_setting',
		redirect: '/company_setting/index',
		component: () => import('@/views/Home'),
		children: [{
				path: 'index',
				name: 'CompanyIndex',
				meta: {
					title: '企业配置',
				},
				component: () => import('@/views/Enterprise/Index'),
			},
			{
				path: 'config',
				name: 'Config',
				meta: {
					title: '应用配置',
					role: '/company_setting/index'
				},
				component: () => import('@/views/Enterprise/Config'),
			},
		]
	},
	// 会话存档
	{
		path: '/session',
		redirect: '/session/index',
		component: () => import('@/views/Home'),
		children: [{
				path: 'index',
				name: 'SessionList',
				meta: {
					title: '消息存档',
				},
				component: () => import('@/views/Session/List')
			},
			{
				path: 'remind',
				name: 'SessionRemind',
				meta: {
					title: '违规提醒',
				},
				component: () => import('@/views/Session/Remind')
			},
			{
				path: 'sensitive',
				name: 'SessionSensitive',
				meta: {
					title: '敏感词库',
				},
				component: () => import('@/views/Session/Sensitive')
			},
			{
				path: 'behavior',
				name: 'SessionBehavior',
				meta: {
					title: '行为监控',
				},
				component: () => import('@/views/Session/Behavior')
			},
			{
				path: 'setting',
				name: 'SessionSetting',
				meta: {
					title: '存档设置',
				},
				component: () => import('@/views/Session/Setting')
			},
		]
	},
	{
		path: '*',
		redirect: '/404',
		children: []
	}
]

export const agentRoutes = [{
		path: '/',
		redirect: '/agent',
		component: () => import('@/views/Home'),
		children: [{
				path: 'menus',
				meta: {
					title: '菜单权限',
				},
				component: () => import('@/views/Menus/index.vue'),
			},
			{
				path: 'agent',
				name: 'Agent',
				meta: {
					title: '代理商管理',
				},
				component: () => import('@/views/Agent'),
			},
			{
				path: 'merchants',
				name: 'Merchants',
				meta: {
					title: '商户管理',
				},
				component: () => import('@/views/merchants/List.vue'),
			},
		]
	},
	{
		path: '*',
		redirect: '/404',
		children: []
	}
]

export const merchantsRoutes = [{
		path: '/',
		redirect: '/merchants',
		component: () => import('@/views/Home'),
		children: [{
			path: 'merchants',
			name: 'Merchants',
			meta: {
				title: '商户管理',
			},
			component: () => import('@/views/merchants/List.vue'),
		}]
	},
	{
		path: '*',
		redirect: '/404',
		children: []
	}
]
//解决路由重复点击报错问题
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	if (typeof(location) == "string") {
		var Separator = "&";
		if (location.indexOf('?') == -1) {
			Separator = '?';
		}
		// location = location + Separator + "t=" + new Date().getTime();
	}
	return routerPush.call(this, location).catch(error => error)
}


const createRouter = () => new Router({
	mode: 'history', // require service support
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

export default router;
