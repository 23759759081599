/**@file: App入口文件 */
import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'babel-polyfill';
import './permission';
import './versionUpdate';
import ElementUI from 'element-ui';
import {
	MessageBox
} from 'element-ui';
import LoginSvg from '@/components/LoginSvg'
import LogoSvg from '@/components/LogoSvg'
import SelectTree from "@/components/SelectTree";
import {
	province,
	citys,
	areaList
} from './util/province';
import '@/assets/css/reset.css';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/iconfont/iconfont.css';
import '@/assets/less/index.less';
import {
	customMessage,
	createImgClose,
	isnumber,
	ismoney,
	oneKeyCopy,
	downloadImg,
	fun_date,
	matchType,
	initImageUrl,
	getParams,
	getUploadOss,
	getUploadAudioOss,
	uploadParams,
} from '@/util/utils';
import 'remixicon/fonts/remixicon.css'
import * as echarts from 'echarts';
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import JsonExcel from 'vue-json-excel'
import axios from 'axios';
import upload from '@/api/upload.js';
import vcolorpicker from 'vcolorpicker'
import HkmStep from '@/hkm/Step.vue';
import loadMore from '@/directive/loadMore.js' // 下拉加载更多
import Auth from '@/directive/auth.js' // 按钮权限
import VueCropper from 'vue-cropper'


Vue.use(VueCropper)
Vue.use(loadMore);
Vue.use(Auth);
Vue.use(vcolorpicker)
Vue.use(VueQuillEditor);
Vue.component('hkm-step', HkmStep);
Vue.component('LoginSvg', LoginSvg);
Vue.component('LogoSvg', LogoSvg);
Vue.component('SelectTree', SelectTree);
Vue.component('downloadExcel', JsonExcel)
Vue.use(ElementUI);

Vue.prototype.$message = customMessage;
Vue.prototype.$closeImg = createImgClose;
Vue.prototype.$province = province;
Vue.prototype.$isnumber = isnumber;
Vue.prototype.$ismoney = ismoney;
Vue.prototype.$copy = oneKeyCopy;
Vue.prototype.$city = citys;
Vue.prototype.$fun_date = fun_date;
Vue.prototype.$downloadImg = downloadImg;
Vue.prototype.matchType = matchType;
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;
Vue.prototype.$upload = upload;
Vue.prototype.$bus = new Vue();
Vue.prototype.$initImageUrl = initImageUrl;
Vue.prototype.$getParams = getParams;
Vue.prototype.$getUploadOss = getUploadOss;
Vue.prototype.$getUploadAudioOss = getUploadAudioOss;
Vue.prototype.$uploadParams = uploadParams;
Vue.prototype.$areaList = areaList();

Vue.config.productionTip = false


export default new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
