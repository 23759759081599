import Vue from 'vue';
import store from '../store/index.js';
export default ({}.install = (Vue, options = {}) => {
	Vue.directive('auth', {
		inserted(el, binding) {
			const value = binding.value;
			if (store.state.setting.wxk_name !== 'hide') {
				el.style.display = 'none';
			}
		}
	});
});
