/** @file:封装axios文件 */
import Qs from 'qs';
import md5 from 'js-md5';
import Vue from '../main';
import {
	customMessage
} from '@/util/utils';
import axios from 'axios';
import router from '../router/index';

const instance = axios.create({});
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 添加请求拦截器
instance.interceptors.request.use(function(config) {
	// 在发送请求之前做些什么
	const {
		id,
		token,
		login_type
	} = JSON.parse(localStorage.getItem('huankemao_user_info'));
	let time = parseInt(new Date().getTime() / 1000);
	let sign = md5(id + time + 'huankemao');
	config.data.user_id = id;
	config.data.token = token;
	config.data.time = time;
	config.data.sign = sign;
	config.data.login_type = login_type;
	config.data = Qs.stringify(config.data);
	return config;
}, function(error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});
// 添加响应拦截器
instance.interceptors.response.use(function(response) {
	switch (response.data.code) {
		case 200:
			return response.data;
		case 500:
			if (response.data.msg == '用户不存在') {
				localStorage.removeItem('huankemao_user_info');
				router.push('/login');
			} else {
				customMessage.error({
					message: response.data.msg
				})
				return false;
			}
			return new Promise(() => {})
		case 501:
			localStorage.removeItem('huankemao_user_info');
			router.push('/login');
			return new Promise(() => {})
		case 502:
		case 503:
			customMessage.error({
				message: response.data.msg
			})
			return false;
	}
}, function(error) {
	return Promise.reject(error);
});

export default instance;
