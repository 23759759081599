const getters = {
	token: state => state.permission.token,
	addRoutes: state => state.permission.addRoutes,
	routes: state => state.permission.routes,
	menus: state => state.permission.menus,
	corp_id: state => state.setting.corp_id,
	wxk_name: state => state.setting.wxk_name,
	configList: state => state.setting.configList,
}
export default getters;
