import {
    getToken
} from '@/util/auth.js';
import {
    asyncRoutes,
    constantRoutes,
    agentRoutes,
    merchantsRoutes
} from '@/router';
import routers from '@/router';
import {
    reqGetMenuList,
} from '@/api/index';

function filterAsyncRoutes(r, menus, arr) {
    for (let i = 0; i < r.length; i++) {
        if (!r[i].redirect) {
            if (menus.indexOf(r[i].meta.role) !== -1) {
                arr.map(item => {
                    if (item.path == r[i].meta.parentPath) {
                        item.children.push(r[i]);
                    }
                })
            }
        } else {
            const obj = {
                ...r[i]
            };
            obj.children = [];
            arr.push(obj);
            filterAsyncRoutes(r[i].children, menus, arr);
        }
    }
}

function filterMenus(menu, arr) {
    menu.forEach(item => {
        if (item.group.length) {
            filterMenus(item.group, arr);
        } else {
            arr.push(item.url);
        }
    })
}

function formatterUrl(r, url) {
    for (let i = 0; i < r.length; i++) {
        if (!r[i].redirect) {
            r[i].meta.parentPath = url;
            if (r[i].meta.role === undefined) {
                if (url === '/') {
                    r[i].meta.role = url + r[i].path;
                } else {
                    r[i].meta.role = url + '/' + r[i].path;
                }
            }
        } else {
            formatterUrl(r[i].children, r[i].path);
        }
    }
}

const state = {
    token: getToken(),
    addRoutes: [],
    routes: [],
    menus: [],
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes;
        state.routes = constantRoutes.concat(routes);
    },
    SET_MENUS: (state, menus) => {
        state.menus = menus;
    },
    SET_AGENT_ROUTES: (state) => {
        state.addRoutes = agentRoutes;
        state.menus = [{
                url: '/agent',
                icon: 'icon-dailishang',
                title: '代理商管理',
                group: []
            },
            {
                url: '/merchants',
                icon: 'icon-shanghuguanli',
                title: '商户管理',
                group: []
            }, {
                url: '/menus',
                icon: 'icon-caidan',
                title: '菜单权限',
                group: []
            },
        ];
    },
    SET_MERCHANTS_ROUTES: (state) => {
        state.addRoutes = merchantsRoutes;
        state.menus = [{
            url: '/merchants',
            icon: 'icon-shanghuguanli',
            title: '商户管理',
            group: []
        }];
    },
}

const actions = {
    getRoutes({
        commit
    }) {
        return new Promise(resolve => {
            reqGetMenuList({}).then(res => {
                commit('SET_MENUS', res.data);
                const roleMenus = [];
                const accessedRoutes = [];
                filterMenus(res.data, roleMenus);
                formatterUrl(asyncRoutes);
                filterAsyncRoutes(asyncRoutes, roleMenus, accessedRoutes);
                commit('SET_ROUTES', accessedRoutes);
                resolve(accessedRoutes);
            })
        })
    },
    getIndexPath({
        commit
    }) {
        return new Promise(resolve => {
            reqGetMenuList({}).then(res => {
                const roleMenus = [];
                filterMenus(res.data, roleMenus);
                resolve(roleMenus[0]);
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}