/** @file:接口文件 */
import Vue from 'vue'
import axios from './axios';
import system from './system';
import agent from './agent';
import upload from './upload';
import {
	_post,
	_upload
} from './request';

let str = '/admin.php';
let str_a = '/agent.php';
var baseURL, imgUrl;
if (process.env.NODE_ENV === 'production') {
	baseURL = '/';
	imgUrl = location.origin;
} else {
	baseURL = 'http://huankemao.saas.brt360.com';
	imgUrl = baseURL;
}
Vue.prototype.baseURL = imgUrl;

axios.defaults.baseURL = baseURL;
system.defaults.baseURL = baseURL;
upload.defaults.baseURL = baseURL;
agent.defaults.baseURL = baseURL;

// 项目初始化同步
export const reqSyncConfig = (option) => axios.post(str + '/config_synchro', option);

// 初始化失败删除配置
export const reqDelConfig = (option) => axios.post(str + '/del_config', option);

// 获取安装配置
export const reqGetConfigRandom = (option) => axios.post(str + '/get_config_random_string', option);

/**@type:系统相关 */
// 登录
export const reqLogin = (option) => system.post(str + '/login', option);

// 重设密码
export const reqForgetPwd = (option) => system.post(str + '/forget_pas', option);

// 发送短信
export const reqSendMag = (option) => system.post(str + '/send_msg', option);

// 注册
export const reqRegister = (option) => system.post(str + '/register', option);

// 验证短信
export const reqVerifyCode = (option) => system.post(str + '/verify_code', option);

// 企业微信登录
export const reqWxLogin = (option) => system.post(str + '/wx_login', option);

export const reqGetWxLogin = (code) => system.get(str + `/wx_login?code=${code}`);

// 退出登录
export const reqLogout = (option) => system.post(str + '/logout', option);

/**@type:首页 */
// 客户统计
export const reqCustomTotal = (option) => axios.post(str + '/index_customer_total', option);

// 客户增长趋势
export const reqCustomTrend = (option) => axios.post(str + '/index_customer_trend', option);

// 客户明细
export const reqCustomDetail = (option) => axios.post(str + '/index_customer_detailed', option);

// 获取首页用户信息
export const reqUserInfo = (option) => axios.post(str + '/index_user_info', option);

// 成员Top10
export const reqStaffTop = (option) => axios.post(str + '/index_staff_top', option);

// 引流数据统计
export const reqDrainage = (option) => axios.post(str + '/index_drainage_data', option);

/**@type:企业通讯录 */
// 同步企业微信成员列表
export const reqSynchroStaff = (option) => axios.post(str + '/synchro_staff', option);

// 获取企业成员最后一次同步时间
export const reqSynchroDate = (option) => axios.post(str + '/get_synchro_staff_date', option);

// 获取成员列表
export const reqGetUserSimpleList = (option) => axios.post(str + '/get_user_simple_list', option);

// 成员标签同步
export const reqSyncStaffTag = (option) => axios.post(str + '/sync_staff_tag', option);

// 获取成员标签组
export const reqStaffTagGroup = (option) => axios.post(str + '/get_staff_tag_group', option);

// 新增编辑成员标签组
export const reqAddStaffTagGroup = (option) => axios.post(str + '/add_staff_tag_group', option);

// 删除成员标签组
export const reqDeleteStaffTagGroup = (option) => axios.post(str + '/del_staff_tag_group', option);

// 新增编辑成员标签
export const reqAddStaffTag = (option) => axios.post(str + '/add_staff_tag', option);

// 删除成员标签
export const reqDeleteStaffTag = (option) => axios.post(str + '/del_staff_tag', option);

// 获取成员标签列表
export const reqGetStaffTagList = (option) => axios.post(str + '/get_staff_tag_list', option);

// 成员打标签/移除标签
export const reqStaffToggle = (option) => axios.post(str + '/staff_tagging', option);

// 成员标签列表
export const reqShowStaffTag = (option) => axios.post(str + '/show_staff_tag', option);

// 获取成员标签树结构
export const reqGetStaffTagTree = (option) => axios.post(str + '/get_staff_tag_tree', option);

// 获取筛选列表
export const reqShowGetStaffScreen = (option) => axios.post(str + '/show_get_staff_screen', option);

// 根据部门code获取成员列表
export const reqDepartmentStaff = (option) => axios.post(str + '/get_department_staff', option);

/**@type:企业微信管理 */
// 企业微信列表
export const reqGetWechatList = (option) => axios.post(str + '/wxk_config_list', option);

// 新增编辑企业微信
export const reqAddWechat = (option) => axios.post(str + '/add_wxk_config', option);

// 获取回调URL
export const reqGetCallbackUrl = (option) => axios.post(str + '/get_callback_url', option);

// 切换默认企业
export const reqChangeCorp = (option) => axios.post(str + '/config_change_corp', option);

// 上传域名验证文件
export const reqUploadFile = `${imgUrl}${str}/upload_domain_verification_file`;

/**@type:客户CRM */
// 同步企业微信客户
export const reqSynchroCustom = (option) => axios.post(str + '/synchro_customer', option);

// 获取客户列表
export const reqGetCustomList = (option) => axios.post(str + '/get_list_customer', option);

//  查看重复客户
export const reqRepeatList = (option) => axios.post(str + '/repeat_list_customer', option);
//打标签
export const customerTag = (option) => axios.post(str + '/customer_tagging', option);
//回显标签
export const showCustomerTag = (option) => axios.post(str + '/show_customer_tag', option);

// 异步处理
export const reqUpdateCusList = (option) => axios.post(str + '/update_customer_tag', option);

// 同步企业微信客户标签
export const reqSynchroTag = (option) => axios.post(str + '/synchro_customer_tag', option);

// 获取客户标签组
export const reqGetCustomTagGroup = (option) => axios.post(str + '/get_customer_tag_group', option);

// 获取客户标签列表
export const reqGetCustomTag = (option) => axios.post(str + '/get_customer_tag', option);

// 获取客户标签表格列表
export const reqGetCustomTagList = (option) => axios.post(str + '/get_customer_tag_list', option);

// 新增客户标签
export const reqAddCustomLabel = (option) => axios.post(str + '/add_customer_tag', option);
//修改删除客户组
export const reqEditCustomLabel = (option) => axios.post(str + '/edit_customer_tag_group', option);
//修改删除客户标签
export const reqEditCustomTag = (option) => axios.post(str + '/edit_customer_tag', option);

// 客户管理筛选条件
export const reqShowList = (option) => axios.post(str + '/show_list_customer', option);

/** @type:活码管理 */
// 获取活码分组列表
export const reqGetLiveCodeGroupList = (option) => axios.post(str + '/live_code_group', option);

// 新增编辑删除活码分组
export const reqAddLiveCodeGroup = (option) => axios.post(str + '/add_code_group', option);

// 获取部门列表
export const reqGetDepartmentList = (option) => axios.post(str + '/get_department_list', option);

// 获取客户标签树结构
export const reqGetCustomTree = (data) => _post(`/admin.php/get_customer_tag_tree`, data);

// 获取活码列表
export const reqGetLiveCodeList = (option) => axios.post(str + '/get_live_qr_list', option);

// 活码列表客户信息
export const reqGetCustomer = (option) => axios.post(str + '/get_live_qr_customer', option);

// 同步配置外部联系权限的联系人
export const reqSynchroFollow = (option) => axios.post(str + '/synchro_follow_user', option);

// 新增活码获取单人成员
export const reqGetSoloStaff = (option) => axios.post(str + '/get_add_live_staff', option);

// 新增活码获取多人成员
export const reqGetManyStaff = (data) => _post(`/admin.php/get_section_tree_staff`, data);

// 新增编辑活码
export const reqAddLiveQr = (option) => axios.post(str + '/add_live_qr', option);

// 编辑活码回显信息
export const reqShowLive = (option) => axios.post(str + '/show_live_qr', option);

// 活码移动分组
export const reqMoveLiveQr = (option) => axios.post(str + '/live_qr_group_move', option);

// 删除活码
export const reqDeleteLiveQr = (option) => axios.post(str + '/delete_live_qr', option);

// 批量获取活码
export const reqBatchLiveQr = (option) => axios.post(str + '/batch_live_qr_list', option);

// 获取素材分组列表
export const reqGetContentGroupList = (option) => axios.post(str + '/content_group_list', option);

// 活码管理预览成员
export const reqGetLiveQrStaff = (option) => axios.post(str + '/get_live_qr_staff', option);

// 批量编辑活码成员
export const reqBatchLiveStaff = (option) => axios.post(str + '/edit_batch_live_qr_staff', option);

// 根据media_id查询
export const reqGetMediaId = (option) => axios.post(str + '/get_media_id_content', option);

// 批量编辑成员上限回显
export const reqShowBatchLimit = (option) => axios.post(str + '/show_batch_add_limit', option);

// 批量编辑活码成员上限
export const reqEditBatchLimit = (option) => axios.post(str + '/edit_batch_add_limit', option);

// 上传活码素材
export const reqUploadQrCode = (option) => axios.post(str + '/upload_qr_code', option);

// 活码获取成员客户信息
export const reqGetLiveQrStaffCustomer = (option) => axios.post(str + '/get_live_qr_staff_customer', option);

// 新增活码激励目标
export const reqAddLiveQrTarget = (option) => axios.post(str + '/add_live_qr_target', option);

// 编辑活码激励目标
export const reqEditLiveQrTarget = (option) => axios.post(str + '/edit_live_qr_target', option);

// 回显活码激励目标
export const reqShowLiveQrTarget = (option) => axios.post(str + '/show_live_qr_target', option);

// 活码确认发放奖励
export const reqLiveQrTargetGrant = (option) => axios.post(str + '/live_qr_target_grant', option);

// 活码名称验重
export const reqExisLiveQrName = (option) => axios.post(str + '/is_live_qr_name_exists', option);

// 图片上传
export const uploadUrl = `${imgUrl}${str}/photo`;

// 素材图片上传
export const reqUploadMaterial = (option) => upload.post(str + '/photo', option);

/**@type:活码统计 */
// 单个活码统计头部信息
export const reqLiveQrStart = (option) => axios.post(str + '/get_live_qr_stat', option);

// 单人活码统计底部信息
export const reqLiveQrEnd = (option) => axios.post(str + '/get_live_qr_stat_screen', option);

// 活码统计头部信息
export const reqGetStatistics = (option) => axios.post(str + '/get_live_qr_statistics', option);

// 活码统计TOP10
export const reqGetTop = (option) => axios.post(str + '/get_live_qr_stat_top', option);

// 活码统计客户增长
export const reqGetAdd = (option) => axios.post(str + '/get_live_qr_add_stat', option);

// 活码统计客户属性
export const reqGetAttribute = (option) => axios.post(str + '/get_live_qr_stat_attribute', option);

/**@type:聊天工具栏 */
// 内容引擎-内容管理-列表
export const ContentList = (option) => axios.post(str + '/content_list', option);

// 内容管理列表（权限）
export const ChatContentList = (option) => axios.post(str + '/chat_content_list', option);

// 内容引擎-内容管理-添加
export const ContentAdd = (option) => axios.post(str + '/content_add', option);
// 内容引擎-内容管理-修改
export const ContentEdit = (option) => axios.post(str + '/content_edit', option);
// 内容引擎-内容管理-统计
export const ContentDetails = (option) => axios.post(str + '/content_details', option);

// 内容引擎-内容管理-刪除
export const ContentDel = (option) => axios.post(str + '/content_del', option);

// 内容引擎-内容管理-上传
export const Upload = (option) => upload.post(str + '/photo', option);

// 内容引擎-内容管理-小组
export const ContentGroupList = (option) => axios.post(str + '/content_group_list', option);

// 内容引擎-内容管理-小组-新增
export const ContentGroupAdd = (option) => axios.post(str + '/content_group_add', option);
// 内容引擎-内容管理-小组-修改
export const ContentGroupEdit = (option) => axios.post(str + '/content_group_edit', option);
// 内容引擎-内容管理-小组-数据回填
export const ContentInfo = (option) => axios.post(str + '/get_temporary_preview', option);

// 获取素材详情
export const reqGetContentEngine = (option) => axios.post(str + '/get_content_engine', option);

// 内容引擎-内容管理-小组-删除
export const ContentGroupDel = (option) => axios.post(str + '/content_group_del', option);

// 内容引擎-内容统计-次数
export const ContentNum = (option) => axios.post(str + '/content_num', option);

// 素材库-内容统计-内容TOP10
export const ContentTop = (option) => axios.post(str + '/content_top', option);

// 素材库-内容统计-员工TOP10
export const StaffTop = (option) => axios.post(str + '/staff_top', option);

// 素材库-内容统计-搜索词TOP10
export const SearchTop = (option) => axios.post(str + '/search_top', option);

// 素材库-内容统计-3种次数
export const ContentSearch = (option) => axios.post(str + '/content_search', option);

//正式发布-文章添加临时文件
export const ContentPriview = (option) => axios.post(str + '/set_content_preview', option);

// 素材库-内容统计-次数
export const ContentdataTotal = (option) => axios.post(str + '/content_data_total', option);

// 素材库-内容统计-详细信息
export const ContentdataDetails = (option) => axios.post(str + '/content_data_details', option);

// 企业管理-员工管理-同步成员行为数据
export const syncStaffBehavior = (option) => axios.post(str + '/sync_staff_behavior', option);

/**代理商接口 */
// 代理商列表
export const reqGetAgentList = (option) => agent.post(str_a + '/get_agent_list', option);

//新增代理商
export const reqAddAgent = (option) => agent.post(str_a + '/add_agent', option);

// 修改代理商状态
export const reqSetAgentState = (option) => agent.post(str_a + '/agent_state', option);

// 编辑代理商回显信息
// export const reqGetAgentInfo = (option) => axios.post(str_a+'/get_agent_info', option);

/**商户接口 */
// 商户列表
export const reqGetMerchantList = (option) => agent.post(str_a + '/get_merchant_list', option);

// 新增商户信息获取
export const reqGetMerchantInfo = (option) => agent.post(str_a + '/add_merchant_show', option);

// 修改商户状态
export const reqChangeMerchantState = (option) => agent.post(str_a + '/merchant_state', option);

// 新增编辑商户
export const reqAddMerchant = (option) => agent.post(str_a + '/add_merchant', option);

// 编辑商户回显
export const reqGetMerchantShow = (option) => agent.post(str_a + '/get_merchant_info', option);

// 管理、代理商登录接口
export const reqAgentLogin = (option) => system.post(str_a + '/agent_login', option);

// 获取登录账号信息
export const reqGetAccountList = (option) => system.post(str + '/get_account_list', option);

/*************************菜单权限****************************/
export const getModuleList = (option) => agent.post(str_a + '/get_module_list', option); // 获取菜单树结构

export const updateModule = (option) => agent.post(str_a + '/module_edit', option); // 更新菜单

export const addModule = (option) => agent.post(str_a + '/add_module', option); //新增菜单

export const delModule = (option) => agent.post(str_a + '/del_module', option); //删除菜单

// 子账户管理
// 获取角色列表
export const reqGetRolesList = (option) => axios.post(str + '/get_roles_list', option);

// 添加角色列表
export const reqAddRole = (option) => axios.post(str + '/roles_add', option);

// 权限设置列表
export const reqShowRolesModule = (option) => axios.post(str + '/show_roles_module', option);

// 编辑角色
export const reqEditRole = (option) => axios.post(str + '/roles_edit', option);

// 角色编辑回显
export const reqShowRolesInfo = (option) => axios.post(str + '/show_roles_info', option);

// 删除角色
export const reqDeleteRole = (option) => axios.post(str + '/roles_del', option);

// 获取成员列表
export const reqGetUserList = (option) => axios.post(str + '/get_user_list', option);

// 添加成员
export const reqAddUser = (option) => axios.post(str + '/user_add', option);

// 编辑成员
export const reqEditUser = (option) => axios.post(str + '/user_edit', option);

// 子账号详情
export const reqGetUserInfo = (option) => axios.post(str + '/get_user_info', option);

// 批量禁用子账号状态
export const reqEditUserDisable = (option) => axios.post(str + '/user_edit_disable', option);

// 子账号重置密码
export const reqResetUserPassword = (option) => axios.post(str + '/user_reset_pwd', option);

// 获取菜单列表
export const reqGetMenuList = (option) => axios.post(str + '/get_menu_list', option);

// 批量修改子账号状态
export const reqBatchEditUserDisable = (option) => axios.post(str + '/user_edit_disable', option);

/**客户群 */
// 客户群列表
export const reqGetCustomGroupList = (option) => axios.post(str + '/get_customer_group_list', option);

// 客户群列表详情
export const reqGetCustomGroupInfo = (option) => axios.post(str + '/get_customer_group_info', option);

// 上传群二维码
export const uploadQrcodeUrl = `${imgUrl}${str}/upload_group_qr_code`;

// 编辑群二维码
export const reqEditGroupQrcode = (option) => axios.post(str + '/edit_customer_group_qr_code', option);

// 新增群激励目标
export const reqAddGroupTarget = (option) => axios.post(str + '/add_customer_group_target', option);

// 编辑群激励目标
export const reqEditGroupTarget = (option) => axios.post(str + '/edit_customer_group_target', option);

// 回显编辑群激励奖励
export const reqShowGroupTarget = (option) => axios.post(str + '/show_customer_group_target', option);

// 自动入群列表
export const reqAutoCustomGroupList = (option) => axios.post(str + '/auto_customer_group_list', option);

// 自动入群客户详情
export const reqAutoCustomGroupInfo = (option) => axios.post(str + '/auto_customer_group_info', option);

// 确认客户群奖励发放
export const reqGroupTargetGrant = (option) => axios.post(str + '/customer_group_target_grant', option);

// 删除自动入群
export const reqDelAutoGroup = (option) => axios.post(str + '/del_auto_group', option);

/**文章引流 */
// 分组列表
export const reqGetArticleGroup = (option) => axios.post(str + '/article_drainage_group', option);

// 列表
export const reqArticleList = (option) => axios.post(str + '/article_drainage_list', option);

// 新增激励目标
export const reqAddArticleTarget = (option) => axios.post(str + '/add_article_target', option);

// 编辑激励目标
export const reqEditArticleTarget = (option) => axios.post(str + '/edit_article_target', option);

// 回显激励目标
export const reqShowArticleTarget = (option) => axios.post(str + '/show_article_target', option);

// 确认文章引流奖励发放
export const reqArticleTargetGrant = (option) => axios.post(str + '/article_target_grant', option);

// 文章引流数据
export const reqArticleStatistics = (option) => axios.post(str + '/article_statistics', option);

// 文章引流添加客户池
export const reqAddPoolArticle = (option) => axios.post(str + '/add_pool_article_drainage', option);

/** 自建应用*/
// 新增自建应用
export const reqAddWxkApp = (option) => axios.post(str + '/add_wxk_app', option);

// 应用列表
export const reqGetAppList = (option) => axios.post(str + '/get_wxk_app_list', option);

// 自建应用详情
export const reqAppDetail = (option) => axios.post(str + '/get_wxk_app_info', option);

// 切换应用
export const reqReplaceApp = (option) => axios.post(str + '/replace_wxk_app', option);

// 客户详情
export const reqGetCustomInfo = (option) => axios.post(str + '/get_customer_info', option);

// 公海企微客户详情
export const reqGetPoolEnterInfo = (option) => axios.post(str + '/wxk_customer_pool_info', option);

// 获取客户画像
export const reqGetCustomPortrait = (option) => axios.post(str + '/get_customer_portrait', option);

// 修改客户详情
export const reqEditCustomInfo = (option) => axios.post(str + '/edit_customer_info', option);

// 客户行业列表
export const reqShowEditPortrait = (option) => axios.post(str + '/show_edit_customer_portrait', option);

// 上传客户画像
export const uploadCustomAvatar = `${imgUrl}${str}/upload_customer_portrait_photo`;

// 添加客户跟进
export const reqAddCustomFollow = (option) => axios.post(str + '/add_customer_follow', option);

// 获取跟进记录列表
export const reqGetCustomFollowList = (option) => axios.post(str + '/get_customer_follow_list', option);

// 获取互动轨迹列表
export const reqGetCustomTrackList = (option) => axios.post(str + '/get_customer_track_list', option);

// 企微客户转交判断是否已拥有该客户
export const reqWxkCustomerShiftJudge = (option) => axios.post(str + '/wxk_customer_shift_judge', option);

/*群打卡*/
// 群打卡列表
export const reqGetPunchList = (option) => axios.post(str + '/get_punch_in_list', option);

// 添加群打卡活动
export const reqAddPunch = (option) => axios.post(str + '/add_punch_in', option);

// 编辑群打卡活动
export const reqEditPunch = (option) => axios.post(str + '/edit_punch_in', option);

// 发布打卡活动
export const reqReleasePunch = (option) => axios.post(str + '/release_punch_in', option);

// 打卡任务情况统计
export const reqGetPunchStatistic = (option) => axios.post(str + '/get_punch_in_task_statistic', option);

// 打卡活动参与详情
export const reqGetPartakeInfo = (option) => axios.post(str + '/get_punch_in_partake_info', option);

// 客户打卡记录
export const reqGetPunchCustomList = (option) => axios.post(str + '/get_punch_in_customer_list', option);

// 打卡拍照记录
export const reqGetPunchPhotoList = (option) => axios.post(str + '/get_punch_in_photo_list', option);

/*修改密码*/
// 发送验证码
export const reqGetSmsCode = (option) => axios.post(str + '/get_sms_code', option);

// 修改个人密码
export const reqEditUserPwd = (option) => axios.post(str + '/edit_user_pwd', option);

/*多企业管理*/
// 修改企业状态
export const reqEditConfigState = (option) => axios.post(str + '/edit_config_state', option);

// 获取企业详情
export const reqGetConfigInfo = (option) => axios.post(str + '/get_config_info', option);

export const uploadFileOss = (option) => upload.post(str + '/upload_file_oss', option);

export const uploadFilesOss = (data, progress) => _upload(`/admin.php/upload_file_oss`, data, progress);

// 公共OSS上传图片
export const reqUploadFileOss = `${imgUrl}${str}/upload_file_oss`;

// 新增编辑活动表单
export const reqAddActiveForm = (option) => axios.post(str + '/add_active_form', option);

// 获取新增活动表单参数
export const reqGetAddActiveForm = (option) => axios.post(str + '/get_add_active_form', option);

// 活动表单列表
export const reqGetActiveList = (option) => axios.post(str + '/get_active_form_list', option);

// 活动表单详情
export const reqActiveFormInfo = (option) => axios.post(str + '/active_form_info', option);

// 发布活动表单
export const reqReleaseActiveForm = (option) => axios.post(str + '/release_active_form', option);

// 活动表单统计
export const reqGetActiveTotal = (option) => axios.post(str + '/active_form_total', option);

// 活动表单统计排名榜
export const reqGetActiveRanking = (option) => axios.post(str + '/get_active_form_total_ranking', option);

// 活动表单引流成员
export const reqGetActiveDrainage = (option) => axios.post(str + '/get_active_form_drainage', option);

// 新增表单
export const reqAddActiveFormField = (option) => axios.post(str + '/add_active_form_field', option);

// 回显新增表单
export const reqShowActiveForm = (option) => axios.post(str + '/show_add_active_form', option);

// 表单列表
export const reqGetActiveFormList = (option) => axios.post(str + '/get_active_form_field', option);

// 编辑表单字段
export const reqEditActiveFormField = (option) => axios.post(str + '/edit_active_form_field', option);

// 编辑表单状态
export const reqEditActiveFormFieldState = (option) => axios.post(str + '/edit_active_form_field_state', option);

// 删除活动表单
export const reqDelActiveList = (option) => axios.post(str + '/del_active_form', option);

// 终止活动表单
export const reqEndActiveList = (option) => axios.post(str + '/end_active_form', option);

// 公共OSS上传音频
export const reqUploadAudioOss = `${imgUrl}${str}/upload_file_oss_voice`;

/*活动裂变*/
// 获取新增活动裂变参数
export const reqGetAddActiveFission = (option) => axios.post(str + '/get_add_active_fission', option);

// 新增编辑活动裂变
export const reqAddActiveFission = (option) => axios.post(str + '/add_active_fission', option);

// 活动裂变列表
export const reqGetFissionList = (option) => axios.post(str + '/get_active_fission_list', option);

// 活动裂变详情
export const reqActiveFissionDetail = (option) => axios.post(str + '/active_fission_info', option);

// 活动裂变发布
export const reqReleaseActiveFission = (option) => axios.post(str + '/release_active_fission', option);

// 活动裂变统计总计
export const reqActiveFissionTotal = (option) => axios.post(str + '/active_fission_total', option);

// 活动裂变统计客户
export const reqActiveFissionDrainage = (option) => axios.post(str + '/active_fission_drainage', option);

// 删除活动裂变
export const reqDelActiveFission = (option) => axios.post(str + '/del_active_fission', option);

// 终止活动裂变
export const reqEndActiveFission = (option) => axios.post(str + '/end_active_fission', option);

// 添加到客户池
export const reqAddPoolActiveFission = (option) => axios.post(str + '/add_pool_active_fission', option);

/*拓客计划*/
// 企业计划列表
export const reqGetCompanyPlanList = (option) => axios.post(str + '/get_business_plan_list', option);

// 回显下拉列表年份
export const reqShowDevelopYear = (option) => axios.post(str + '/show_develop_custom_year', option);

// 添加编辑企业拓客计划
export const reqAddDevelopCustom = (option) => axios.post(str + '/add_develop_custom', option);

// 新增编辑成员拓客计划
export const reqAddStaffDevelopCustom = (option) => axios.post(str + '/add_staff_develop_custom', option);

// 企业拓客+成员拓客+成员激励详情完成情况
export const reqGetDevelopCustomInfo = (option) => axios.post(str + '/get_develop_custom_info', option);

/*邀请有奖*/
// 邀请有奖列表
export const reqGetInvitList = (option) => axios.post(str + '/get_invite_award_list', option);

// 新增邀请有奖参数获取
export const reqGetInvitParams = (option) => axios.post(str + '/get_add_invite_award', option);

// 新增编辑邀请有奖
export const reqAddInvitAward = (option) => axios.post(str + '/add_invite_award', option);

// 邀请有奖详情
export const reqGetInviteAwardInfo = (option) => axios.post(str + '/get_invite_award_info', option);

// 邀请有奖发布
export const reqReleaseInviteAward = (option) => axios.post(str + '/release_invite_award', option);

// 邀请有奖统计总计
export const reqGetInviteAwardTotal = (option) => axios.post(str + '/get_invite_award_total', option);

// 邀请有奖统计客户
export const reqInviteAwardDrainage = (option) => axios.post(str + '/invite_award_drainage', option);

// 删除邀请有奖
export const reqDelInviteList = (option) => axios.post(str + '/del_invite_award', option);

// 终止邀请有奖
export const reqEndInviteList = (option) => axios.post(str + '/end_invite_award', option);

// 添加客户池
export const reqAddPoolInviteAward = (option) => axios.post(str + '/add_pool_invite_award', option);

/* Public 处理活动二维码 */
export const reqUploadActiveCode = (option) => axios.post(str + '/upload_active_qr_code', option);

/* 会话存档 */
// 会话存档成员列表
export const reqGetChatStaffList = (option) => axios.post(str + '/get_chat_staff_list', option);

// 获取成员聊天列表
export const reqGetStaffChatMsg = (option) => axios.post(str + '/get_staff_chat_msg', option);

// 获取聊天记录
export const reqGetChatArchiving = (option) => axios.post(str + '/get_chat_archiving', option);

// 敏感词列表
export const reqGetSensitiveWordsList = (option) => axios.post(str + '/get_sensitive_words_list', option);

// 添加敏感词
export const reqAddSensitiveWords = (option) => axios.post(str + '/add_sensitive_words', option);

// 编辑敏感词
export const reqEditSensitiveWords = (option) => axios.post(str + '/edit_sensitive_words', option);

// 回显敏感词
export const reqShowSensitiveWords = (option) => axios.post(str + '/show_sensitive_words', option);

// 删除敏感词
export const reqDelSensitiveWords = (option) => axios.post(str + '/del_sensitive_words', option);

// 违规行为列表
export const reqGetViolationList = (option) => axios.post(str + '/get_violation_list', option);

// 编辑违规行为
export const reqEditViolation = (option) => axios.post(str + '/edit_violation', option);

// 回显违规行为
export const reqShowViolation = (option) => axios.post(str + '/show_violation', option);

// 违规提醒列表
export const reqGetRemindList = (option) => axios.post(str + '/get_remind_list', option);

// 处理违规
export const reqHandleViolation = (option) => axios.post(str + '/violation_handle', option);

// 同步企业会话存档
export const reqSynCorpChat = (option) => axios.post(str + '/syn_corp_chat', option);

// 获取ip地址
export const reqGetIp = (option) => axios.post(str + '/get_ip', option);

/* 标签拉群 */
// 标签拉群列表
export const reqGetTagPullCrowdList = (option) => axios.post(str + '/get_tag_pull_crowd_list', option);

// 新增标签拉群
export const reqAddTagPullCrowd = (option) => axios.post(str + '/add_tag_pull_crowd', option);

// 编辑标签拉群
export const reqEditTagPullCrowd = (option) => axios.post(str + '/edit_tag_pull_crowd', option);

// 根据标签查找客户数量
export const reqGetTagPullCrowdSeek = (option) => axios.post(str + '/tag_pull_crowd_seek', option);

// 回显标签拉群
export const reqShowTagPullCrowd = (option) => axios.post(str + '/show_tag_pull_crow', option);

// 发布标签拉群
export const reqReleaseTagPullCrowd = (option) => axios.post(str + '/release_tag_pull_crowd', option);

// 标签拉群更新数据
export const reqUpdateTagPullCrowdData = (option) => axios.post(str + '/update_tag_pull_crowd_data', option);

// 标签拉群总计
export const reqTagPullCrowdTotal = (option) => axios.post(str + '/tag_pull_crowd_total', option);

// 标签拉群发送记录
export const reqTagPullCrowdSend = (option) => axios.post(str + '/tag_pull_crowd_send_list', option);

// 删除标签拉群
export const reqDelTagPullCrowd = (option) => axios.post(str + '/del_tag_pull_crowd', option);

/*离职继承*/
// 离职继承列表
export const reqGetQuitInheritList = (option) => axios.post(str + '/get_quit_inherit_list', option);

// 离职继承分配详情
export const reqGetQuitInheritInfo = (option) => axios.post(str + '/get_quit_inherit_info', option);

// 离职继承分配客户
export const reqQuitInheritCustomer = (option) => axios.post(str + '/quit_inherit_customer', option);

// 离职继承分配客户群
export const reqQuitInheritGroup = (option) => axios.post(str + '/quit_inherit_chat_group', option);

// 更新离职继承接替情况
export const reqQuitInheritUpdate = (option) => axios.post(str + '/quit_inherit_update_data', option);

/* 删人管理 */
// 生效成员列表
export const reqGetTakeEffectList = (option) => axios.post(str + '/take_effect_list', option);

// 新增生效员工
export const reqAddTakeEffectList = (option) => axios.post(str + '/add_take_effect_staff', option);

// 编辑生效员工
export const reqEditTakeEffectList = (option) => axios.post(str + '/edit_take_effect_staff', option);

// 生效员工回显数据
export const reqShowTakeEffect = (option) => axios.post(str + '/show_take_effect', option);

// 修改状态
export const reqBatchOpenTakeEffect = (option) => axios.post(str + '/batch_open_take_effect', option);

// 删人记录列表
export const reqGetDelPersonList = (option) => axios.post(str + '/get_del_person_list', option);

// 非企微客户字段管理列表
export const reqEnterCustomerFieldList = (option) => axios.post(str + '/enter_customer_field_list', option);

// 添加非企微客户字段管理
export const reqAddEnterCustomerField = (option) => axios.post(str + '/add_enter_customer_field', option);

// 编辑非企微客户字段管理
export const reqEditEnterCustomerField = (option) => axios.post(str + '/edit_enter_customer_field', option);

// 删除非企微客户字段管理
export const reqDelEnterCustomerField = (option) => axios.post(str + '/del_enter_customer_field', option);

// 编辑客户公海回收规则
export const reqEditRecoveryRule = (option) => axios.post(str + '/edit_recovery_rule', option);

// 回显客户公海回收规则
export const reqShowRecoveryRule = (option) => axios.post(str + '/show_recovery_rule', option);

// 添加非企微客户
export const reqAddEnterCustomer = (option) => axios.post(str + '/add_enter_customer', option);

// 非企微客户列表
export const reqGetEnterCustomerList = (option) => axios.post(str + '/get_enter_customer_list', option);

// 回显新增编辑非企微客户数据
export const reqShowEditEnterCustomer = (data) => _post(`/admin.php/show_edit_enter_customer`, data);

// 导入非企微客户
export const reqUploadFileImport = `${imgUrl}${str}/enter_customer_import`;

// 导出非企微客户
export const reqExportCustomer = (option) => axios.post(str + '/export_customer_import', option);

// 非企微客户打标签/移除标签
export const reqEnterCustomerTagging = (option) => axios.post(str + '/enter_customer_tagging', option);

// 转交非企微客户
export const reqShiftEnterCustomer = (option) => axios.post(str + '/shift_enter_customer', option);

// 非企微客户退回公海
export const reqBackPoolEnterCustomer = (option) => axios.post(str + '/back_pool_enter_customer', option);

// 非企微客户详情
export const reqGetEnterCustomerInfo = (option) => axios.post(str + '/enter_customer_info', option);

// 修改非企微客户详情
export const reqEditEnterCustomerInfo = (option) => axios.post(str + '/edit_enter_customer_info', option);

// 非企微客户跟进记录列表
export const reqEnterCustomerFollowList = (option) => axios.post(str + '/enter_customer_follow_list', option);

// 添加非企微客户跟进
export const reqAddEnterCustomerFollowList = (option) => axios.post(str + '/add_enter_customer_follow', option);

// 非企微客户互动轨迹列表
export const reqGetEnterCustomerTrackList = (option) => axios.post(str + '/enter_customer_track_list', option);

// 获取非企微客户画像
export const reqGetEnterCustomerPortrait = (option) => axios.post(str + '/enter_customer_portrait', option);

// 编辑非企微客户画像
export const reqEditEnterCustomerPortrait = (option) => axios.post(str + '/edit_enter_customer_info', option);

// 客户池列表
export const reqGetCustomerPoolList = (option) => axios.post(str + '/get_customer_pool_list', option);

// 客户池添加客户
export const reqAddCustomerPool = (option) => axios.post(str + '/add_customer_pool', option);

// 导入客户池客户
export const reqCustomerPoolImport = `${imgUrl}${str}/customer_pool_import`;

// 导出客户池客户
export const reqExportCustomerPool = (option) => axios.post(str + '/export_customer_pool', option);

// 删除客户池客户
export const reqDelCustomerPool = (option) => axios.post(str + '/del_customer_pool', option);

// 分配客户池客户
export const reqAllotCustomerPool = (option) => axios.post(str + '/allocation_customer_pool', option);

// 企微客户池列表
export const reqGetWxkCustomerPoolList = (option) => axios.post(str + '/wxk_customer_pool_list', option);

// 企微客户转交
export const reqWxkCustomerShift = (option) => axios.post(str + '/wxk_customer_shift', option);

// 企微客户退回公海
export const reqWxkBackCustomerPool = (option) => axios.post(str + '/wxk_back_customer_pool', option);

/********************************抽奖助手******************************************/
// 获取新增抽奖助手参数
export const reqGetAddLuckDraw = (option) => axios.post(str + '/get_add_lottery_helper', option);

// 新增编辑抽奖助手
export const reqAddLuckDraw = (option) => axios.post(str + '/add_lottery_helper', option);

// 获取抽奖助手列表
export const reqGetLuckDrawList = (option) => axios.post(str + '/get_lottery_helper_list', option);

// 获取抽奖助手详情
export const reqGetLuckDrawInfo = (option) => axios.post(str + '/get_lottery_helper_info', option);

// 抽奖助手发布
export const reqReleaseLuckDraw = (option) => axios.post(str + '/release_lottery_helper', option);

// 终止抽奖助手
export const reqEndLuckDraw = (option) => axios.post(str + '/end_lottery_helper', option);

// 删除抽奖助手
export const reqDelLuckDraw = (option) => axios.post(str + '/del_lottery_helper', option);

// 抽奖助手统计总计
export const reqLuckDrawTotal = (option) => axios.post(str + '/get_lottery_helper_total', option);

// 抽奖助手统计客户
export const reqLuckDrawCustomer = (option) => axios.post(str + '/lottery_helper_drainage', option);

// 抽奖助手添加客户池
export const reqAddPoolLuckDraw = (option) => axios.post(str + '/add_pool_lottery_helper', option);

// 客户SOP
export const getSopList = (option) => axios.post(str + '/get_sop_list', option); //sop列表

export const editSopState = (option) => axios.post(str + '/edit_sop_state', option); //编辑客户sop状态

export const delSop = (option) => axios.post(str + '/del_sop', option); // 删除sop

export const getSopInfo = (option) => axios.post(str + '/get_sop_info', option); //获取sop详情内容

export const addSopMsg = (option) => axios.post(str + '/add_sop_msg', option); //新增sop内容

export const editSopMsg = (option) => axios.post(str + '/edit_sop_msg', option); //编辑sop内容

export const delSopMsg = (option) => axios.post(str + '/del_sop_msg', option); //删除sop内容

export const getSopSendInfo = (option) => axios.post(str + '/sop_send_info', option); //获取推送详情

export const addSop = (option) => axios.post(str + '/add_sop', option); //新增sop

export const editSop = (option) => axios.post(str + '/edit_sop', option); //编辑sop

export const reqGetClueList = (option) => axios.post(str + '/get_customer_clue_list', option); // 客户线索列表

export const reqAddClue = (option) => axios.post(str + '/add_customer_clue', option); // 添加客户线索

export const reqEditCustomerClue = (data) => _post(`/admin.php/edit_customer_clue`, data); // 修改客户线索

export const reqCustomerClueInfo = (data) => _post(`/admin.php/customer_clue_info`, data); //客户线索详情

export const reqCustomerClueTrackList = (data) => _post(`/admin.php/customer_clue_track_list`, data); //客户线索互动轨迹列表

export const reqCustomerClueChange = (data) => _post(`/admin.php/customer_clue_change`, data); //客户线索转为客户

export const reqDelCustomerClue = (data) => _post(`/admin.php/del_customer_clue`, data); // 删除客户线索

export const reqEnterCustomerMatchList = (data) => _post(`/admin.php/enter_customer_match_list`, data); // 匹配企微客户列表

export const reqEnterCustomerMatch = (data) => _post(`/admin.php/enter_customer_match`, data); //匹配企微客户

export const reqAddArticleTag = (data) => _post(`/admin.php/add_article_tag`, data); //新增编辑文章阅读标签设置

export const reqShowAddArticleTag = (data) => _post(`/admin.php/show_add_article_tag`, data); //回显文章阅读标签设置

export const reqShowAddLayered = (data) => _post(`/admin.php/show_add_layered`, data); //回显新增分层

export const reqAddLayered = (data) => _post(`/admin.php/add_layered`, data); // 新增分层组

export const reqEditLayered = (data) => _post(`/admin.php/edit_layered`, data); //编辑分层

export const reqDelLayered = (data) => _post(`/admin.php/del_layered`, data); //删除分层

export const reqGetLayeredList = (data) => _post(`/admin.php/get_layered_list`, data); // 分层组列表

export const reqGetLayeredInfo = (data) => _post(`/admin.php/get_layered_info`, data); //获取分层组规则

export const reqGetActiveFormStaffList = (data) => _post(`/admin.php/get_active_form_staff_list`, data); //活动表单列表引流成员

export const reqGetActiveFissionStaffList = (data) => _post(`/admin.php/get_active_fission_staff_list`,
	data); //活动裂变列表引流成员

export const reqGetInviteAwardStaffList = (data) => _post(`/admin.php/get_invite_award_staff_list`, data); //邀请有奖列表引流成员

export const reqGetLotteryHelperStaffList = (data) => _post(`/admin.php/get_lottery_helper_staff_list`,
	data); //抽奖助手列表引流成员

export const reqShowCustomerGroupTag = (data) => _post(`/admin.php/show_customer_group_tag`, data); //客户群打标签回显已有的标签

export const reqCustomerGroupTagging = (data) => _post(`/admin.php/customer_group_tagging`, data); //客户群打标签/移除标签

export const reqIndexCustomerClue = (data) => _post(`/admin.php/index_customer_clue`, data); //首页线索统计

export const reqShowLayeredStat = (data) => _post(`/admin.php/show_layered_stat`, data); //回显客户分层统计

export const reqGetLayeredStat = (data) => _post(`/admin.php/get_layered_stat`, data); //获取客户分层统计
