<template>
  <svg
    t="1611215769323"
    class="icon"
    viewBox="0 0 1112 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="1252"
    width="55"
    height="50"
  >
    <path
      d="M173.34096 376.14c11.323 3.64 17.39 15.772 13.75 26.893-3.64 11.323-15.772 17.39-26.894 13.75l-83.914-27.298c-11.324-3.64-17.39-15.772-13.75-26.893 3.64-11.323 15.772-17.39 26.893-13.75l83.915 27.298z m-0.607 376.503c11.323-3.64 23.253 2.426 26.893 13.75s-2.426 23.253-13.75 26.893l-83.914 27.297a21.231 21.231 0 0 1-26.893-13.75c-3.64-11.323 2.426-23.253 13.75-26.893l83.914-27.297zM155.54696 565.2c11.727 0.809 20.624 10.92 20.018 22.85a21.636 21.636 0 0 1-22.85 20.017l-132.847-9.301C8.14096 597.755-0.75704 587.443 0.05196 575.715s10.92-20.625 22.85-20.018l132.645 9.503zM1017.33796 281.71c-19.21-84.318-54.393-165.402-103.326-234.96-18.603-26.286-29.522-19.411-45.091-12.536-20.22 8.897-55.808 60.256-93.014 83.308-39.834-11.324-81.892-17.188-125.366-17.188h-7.684c-10.919-36.8-70.771-139.116-99.888-84.723-7.28 19.007-50.551 93.216-65.11 118.087a467.292 467.292 0 0 0-149.833 96.653c-13.345 13.346-15.772 10.717-42.462 12.739-58.235 4.448-96.452 100.9-4.247 137.498L699.07096 582.792c33.97 16.378 41.452 17.996 78.657 12.334l34.375-5.055c50.348-6.268 30.128 160.55-27.298 120.514l-14.559-10.313c-40.642-28.915-39.631-28.713-87.351-14.356L297.29096 802.587c-28.106 4.853-39.025 25.073-0.404 55.808 89.778 106.36 218.178 165.403 353.452 165.403 254.978 0.202 461.833-206.652 461.833-461.63 0-105.551-35.386-202.609-94.834-280.457z m-556.464 25.883a59.246 59.246 0 0 1-49.944-27.298c5.257 4.247 11.93 6.673 19.21 6.673 17.389 0 31.543-14.154 31.543-31.544a31.584 31.584 0 0 0-60.055-13.75 59.367 59.367 0 0 1 59.044-52.775c32.757 0 59.448 26.489 59.65 59.246 0 32.757-26.691 59.448-59.448 59.448z m195.53 94.227c-22.646 0-42.866-9.706-57.02-25.074 5.459 2.831 11.727 4.247 18.4 4.247 22.647 0 41.047-18.4 41.047-41.048a41.068 41.068 0 0 0-79.668-14.154v-1.213a77.242 77.242 0 1 1 77.242 77.242z"
      fill="#FFFFFF"
      p-id="1253"
    ></path>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
