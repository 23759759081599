<template>
	<div class="hkm-step">
		<div class="hkm-step-main" v-for="(item,index) in title">
			<div
				:class="active==index+1?'hkm-step-head is-process':active>index+1?'hkm-step-head is-success':active <index+1?'hkm-step-head is-wait':'hkm-step-head'">
				<span v-if="active<=index+1">{{index+1}}</span>
				<i v-else class="el-icon-check"></i>
			</div>
			<div :class="index==title.length-1?'hkm-step-text is-end':'hkm-step-text'">
				{{item}}
			</div>
			<div :class="active>index+1?'hkm-step-line is-active':'hkm-step-line'" v-if="index!=title.length-1"></div>
		</div>
	</div>
</template>

<script>
	/**
	 * @param {title} 接受一个数组，数组长度作为步骤条的长度 ;例如：['基本设置','引流设置','欢迎语设置'] 
	 */
	export default {
		props: {
			title: {
				default: [],
				type: Array
			},
			active: {
				default: 1,
				type: Number
			}
		},
		data() {
			return {

			}
		},
		mounted() {
			this.$nextTick(() => {
				[...document.querySelectorAll('.hkm-step-main')].forEach((ele, index) => {
					if (index != this.title.length - 1) {
						ele.style.flexBasis = 1 / (this.title.length - 1) * 100 + '%';
					}
				})
			})
		},
		methods: {

		}
	}
</script>

<style lang='less' scoped>
	.hkm-step {
		display: flex;
		align-items: center;
		margin-bottom: 45px;

		.hkm-step-main:first-of-type {
			margin-left: 0;
		}

		.hkm-step-main {
			position: relative;
			flex-shrink: 1;
			display: flex;
			align-items: center;
			margin-left: 15px;

			.hkm-step-head {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 20px;

				&.is-wait {
					background: #E5EBFB;
				}

				&.is-process {
					background-color: #6881EC;
				}

				&.is-success {
					background-color: #6881EC;
				}
			}

			.hkm-step-text {
				font-size: 14px;
				font-weight: 400;
				color: #333;
				line-height: 20px;
				margin: 0 15px;
				word-break: keep-all;

				&.is-end {
					margin-right: 0px;
				}
			}

			.hkm-step-line {
				height: 10px;
				border-radius: 5px;
				flex-grow: 1;
				background-color: #E5EBFB;

				&.is-active {
					background: #6881EC;
				}
			}
		}
	}
</style>
