<template>
	<div id="app">
		<router-view v-if="isLoad" @load="load" />
		<HelpDialog v-if="show" />
	</div>
</template>
<script>
import HelpDialog from '@/components/HelpDialog.vue';
export default {
	components: {
		HelpDialog
	},
	data() {
		return {
			isLoad: true,
			show: false
		};
	},
	mounted() {
		this.$bus.$on('showHelp', index => {
			this.show = true;
			this.$nextTick(() => {
				this.$bus.$emit('showHelpDialog', index);
			});
		});
	},
	destroyed() {
		this.$bus.$off('showHelp');
	},
	methods: {
		load() {
			this.isLoad = false;
			this.$nextTick(() => {
				this.isLoad = true;
			});
		}
	}
};
</script>

<style lang="less"></style>
