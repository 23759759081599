import {
	reqGetWechatList
} from '@/api/index';

const state = {
	corp_id: '',
	wxk_name: '',
	use_id: '',
	configList: []
}

const mutations = {
	SET_WXCONFIG_CORPID: (state, corp_id) => {
		state.corp_id = corp_id;
	},
	SET_WXCONFIG_WXNAME: (state, wxk_name) => {
		state.wxk_name = wxk_name;
	},
	SET_WXCONFIG_LIST: (state, configList) => {
		state.configList = configList;
	},
	SET_WXCONFIG_USEID: (state, use_id) => {
		state.use_id = use_id;
	}
}

const actions = {
	getWxkConfig({
		commit
	}) {
		return new Promise(resolve => {
			reqGetWechatList({
				page: 1,
				limit: 100,
			}).then(res => {
				if (res.data.length) {
					let arr = res.data.filter(item => {
						return Boolean(item.wxk_id) && Boolean(item
							.wxk_address_book_secret) && Boolean(item
							.wxk_customer_admin_secret)
					});
					commit('SET_WXCONFIG_LIST', arr);
					let config = res.data.filter(val => {
						return val.is_use == 1;
					})
					if (config.length) {
						commit('SET_WXCONFIG_CORPID', config[0].wxk_id);
						commit('SET_WXCONFIG_WXNAME', config[0].wxk_name);
						commit('SET_WXCONFIG_USEID', config[0].id);
					} else {
						commit('SET_WXCONFIG_CORPID', null);
						commit('SET_WXCONFIG_WXNAME', '');
						commit('SET_WXCONFIG_USEID', '');
					}
				} else {
					commit('SET_WXCONFIG_CORPID', null);
					commit('SET_WXCONFIG_WXNAME', '');
					commit('SET_WXCONFIG_USEID', '');
				}
				resolve();
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
