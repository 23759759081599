/** @file:工具文件 */
import {
	Loading
} from 'element-ui'
import md5 from 'js-md5';
import vue from '../main';
import Clipboard from "clipboard";
import store from '@/store';
import Qs from 'qs';

// 计算百分比
export function GetPercent(num, total) {
	/// <summary>
	/// 求百分比
	/// </summary>
	/// <param name="num">当前数</param>
	/// <param name="total">总数</param>
	num = parseFloat(num);
	total = parseFloat(total);
	if (isNaN(num) || isNaN(total)) {
		return "-";
	}
	return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00) + "%";
}

/**重置message，防止重复点击重复弹出message弹框 */
import {
	Message
} from 'element-ui';
let messageInstance = null;
const resetMessage = (options) => {
	if (messageInstance) {
		messageInstance.close();
	}
	messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach(type => {
	resetMessage[type] = options => {
		if (typeof options === 'string') {
			options = {
				message: options
			}
		}
		options.type = type
		return resetMessage(options)
	}
})
export const customMessage = resetMessage;

// 公共上传图片
export const getUploadOss = () => {
	const query = {};
	const {
		user_id,
		token
	} = JSON.parse(localStorage.getItem('huankemao_user_info'));
	const time = parseInt(new Date().getTime() / 1000);
	query.corp_id = store.state.setting.corp_id;
	const sign = md5(token + time + md5('huankemao/admin.php/upload_file_oss' + Qs.stringify(query)));
	const obj = {
		user_id,
		token,
		time,
		sign
	};
	const headers = {
		Auth: JSON.stringify(obj)
	};
	return {
		query,
		headers
	}
};
// 公共上传音频
export const getUploadAudioOss = () => {
	const query = {};
	const {
		user_id,
		token
	} = JSON.parse(localStorage.getItem('huankemao_user_info'));
	const time = parseInt(new Date().getTime() / 1000);
	query.corp_id = store.state.setting.corp_id;
	const sign = md5(token + time + md5('huankemao/admin.php/upload_file_oss_voice' + Qs.stringify(query)));
	const obj = {
		user_id,
		token,
		time,
		sign
	};
	const headers = {
		Auth: JSON.stringify(obj)
	};
	return {
		query,
		headers
	}
};

// 素材库上传图片
export const getParams = (type = 2) => {
	const query = {};
	const {
		user_id,
		token
	} = JSON.parse(localStorage.getItem('huankemao_user_info'));
	const time = parseInt(new Date().getTime() / 1000);
	query.corp_id = store.state.setting.corp_id;
	query.type = type;
	const sign = md5(token + time + md5('huankemao/admin.php/photo' + Qs.stringify(query)));
	const obj = {
		user_id,
		token,
		time,
		sign
	};
	const headers = {
		Auth: JSON.stringify(obj)
	};
	return {
		query,
		headers
	}
};

// 上传公共参数
export let uploadParams = (url) => {
	let query = {};
	let {
		user_id,
		token
	} = JSON.parse(localStorage.getItem('huankemao_user_info'));
	let time = parseInt(new Date().getTime() / 1000);
	query.corp_id = store.state.setting.corp_id;
	let sign = md5(token + time + md5('huankemao/admin.php/' + url + Qs.stringify(query)));
	let obj = {
		user_id,
		token,
		time,
		sign
	};
	let headers = {
		Auth: JSON.stringify(obj)
	};
	return {
		query,
		headers
	}
}
// 解决预览图片点击model无法关闭的问题
export const createImgClose = () => {
	vue.$nextTick(() => {
		// 获取遮罩层dom
		let domImageMask = document.querySelector(".el-image-viewer__mask");
		if (!domImageMask) {
			return;
		}
		domImageMask.addEventListener("click", (e) => {
			// 点击遮罩层时调用关闭按钮的 click 事件
			document.querySelector(".el-image-viewer__close") ?
				document.querySelector(".el-image-viewer__close").click() :
				"";
			e.stopPropagation();
		});
	});
}
// 输入正整数
export const isnumber = (val, min, max) => {
	val = val.replace(/[^0-9]/gi, "") || '';
	if (min) {
		val <= min ? val = min : '';
	}
	if (max) {
		val >= max ? val = max : '';
	}
	return Number(val);
};

// 金额格式过滤
export const ismoney = (val) => {
	val <= 0 ? val = '' : '';
	return val;
}

// 计算百分比
export const getPercent = (num, total) => {
	num = parseFloat(num);
	total = parseFloat(total);
	if (isNaN(num) || isNaN(total)) {
		return "-";
	}
	return total <= 0 ? 0 : (Math.round(num / total * 10000) / 100.00);
}

// 一键复制
export const oneKeyCopy = (e, text) => {
	const clipboard = new Clipboard(e.target, {
		text: () => text
	});
	clipboard.on("success", (e) => {
		Message.success("复制成功");
		// 释放内存
		clipboard.off("error");
		clipboard.off("success");
		clipboard.destroy();
	});
	clipboard.onClick(e);
};

// 解决图片跨域
export const initImageUrl = (url) => {
	// 把现在的图片连接传进来，返回一个不受限制的路径
	if (url !== undefined) {
		return url.replace(/^(http)[s]*(\:\/\/)/, 'https://images.weserv.nl/?url=');
	}
};

// 同源下载图片
export const downloadImg = (imgUrl, imgName) => {
	var a = document.createElement("a");
	a.href = imgUrl; //  href链接指向图片的地址
	a.download = imgName; //  下载的图片的名称
	a.click(); //  触发a标签的单击事件
};

// 非同源下载图片
export const downloadImage = (imgsrc, name) => { //下载图片地址和图片名
	const loading = Loading.service({
		lock: true,
		text: '下载中...',
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.7)'
	});
	var image = new Image();
	// 解决跨域 Canvas 污染问题
	image.setAttribute("crossOrigin", "anonymous");
	image.onload = function() {
		var canvas = document.createElement("canvas");
		canvas.width = image.width;
		canvas.height = image.height;
		var context = canvas.getContext("2d");
		context.drawImage(image, 0, 0, image.width, image.height);
		var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
		var a = document.createElement("a"); // 生成一个a元素
		var event = new MouseEvent("click"); // 创建一个单击事件
		a.download = name || "photo"; // 设置图片名称
		a.href = url; // 将生成的URL设置为a.href属性
		a.dispatchEvent(event); // 触发a的单击事件
		loading.close();
	};
	image.src = initImageUrl(imgsrc) + '&w=300';
}

// 获取aa天前/后的日期(正数表示后，负数表示前)
export const fun_date = (aa) => {
	var date1 = new Date(),
		time1 =
		date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate(); //time1表示当前时间
	var date2 = new Date(date1);
	date2.setDate(date1.getDate() + aa);
	var y = date2.getFullYear();
	var m =
		date2.getMonth() + 1 < 10 ? "0" + (date2.getMonth() + 1) : date2.getMonth() + 1;
	var d = date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate();
	var time2 = y + "-" + m + "-" + d;
	return time2;
};
// 根据后缀区分文件类型
export const matchType = (fileName) => {
	// 后缀获取
	var suffix = '';
	// 获取类型结果
	var result = '';
	try {
		var flieArr = fileName.split('.');
		suffix = flieArr[flieArr.length - 1];
	} catch (err) {
		suffix = '';
	}
	// fileName无后缀返回 false
	if (!suffix) {
		result = false;
		return result;
	}
	// 图片格式
	var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
	// 进行图片匹配
	result = imglist.some(function(item) {
		return item == suffix;
	});
	if (result) {
		result = 'image';
		return result;
	};
	// 匹配txt
	var txtlist = ['txt'];
	result = txtlist.some(function(item) {
		return item == suffix;
	});
	if (result) {
		result = 'txt';
		return result;
	};
	// 匹配 excel
	var excelist = ['xls', 'xlsx'];
	result = excelist.some(function(item) {
		return item == suffix;
	});
	if (result) {
		result = 'excel';
		return result;
	};
	// 匹配 word
	var wordlist = ['doc', 'docx'];
	result = wordlist.some(function(item) {
		return item == suffix;
	});
	if (result) {
		result = 'word';
		return result;
	};
	// 匹配 pdf
	var pdflist = ['pdf'];
	result = pdflist.some(function(item) {
		return item == suffix;
	});
	if (result) {
		result = 'pdf';
		return result;
	};
	// 匹配 ppt
	var pptlist = ['ppt'];
	result = pptlist.some(function(item) {
		return item == suffix;
	});
	if (result) {
		result = 'ppt';
		return result;
	};
	// 匹配 视频
	var videolist = ['Ogg', 'ogg', 'mp4', 'MP4', 'WebM', 'webm'];
	result = videolist.some(function(item) {
		return item == suffix;
	});
	if (result) {
		result = 'video';
		return result;
	};
	// 匹配 音频
	var radiolist = ['MP3', 'mp3', 'wav', 'Wav', 'Ogg', 'ogg'];
	result = radiolist.some(function(item) {
		return item == suffix;
	});
	if (result) {
		result = 'audio';
		return result;
	}
	// 其他 文件类型
	result = 'other';
	return result;
};


// 获取浏览器缩放比例
export const detectZoom = () => {
	var ratio = 0,
		screen = window.screen,
		ua = navigator.userAgent.toLowerCase();

	if (window.devicePixelRatio !== undefined) {
		ratio = window.devicePixelRatio;
	} else if (~ua.indexOf('msie')) {
		if (screen.deviceXDPI && screen.logicalXDPI) {
			ratio = screen.deviceXDPI / screen.logicalXDPI;
		}
	} else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
		ratio = window.outerWidth / window.innerWidth;
	}

	if (ratio) {
		ratio = Math.round(ratio * 100);
	}

	return ratio;
};
