import Vue from 'vue';
import VueRouter from 'vue-router';
import router, {
	agentRoutes,
	merchantsRoutes
} from './router';
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import store from './store';
import {
	MessageBox
} from 'element-ui';
import {
	getToken
} from '@/util/auth.js';

NProgress.configure({
	showSpinner: false
}) // NProgress Configuration
const whiteList = ['/login', '/404'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
	NProgress.start();
	const hasToken = getToken();
	const hasUserInfo = localStorage.getItem('huankemao_user_info');
	var login_type;
	try {
		login_type = JSON.parse(localStorage.getItem('huankemao_user_info')).login_type;
	} catch (e) {
		//TODO handle the exception
		login_type = undefined;
	}
	if (hasToken && hasUserInfo) {
		if (login_type === 'sys_admin_user') { // 管理端
			if (to.path === '/login' || to.path === '/') {
				next('/agent');
			} else {
				if (store.state.permission.addRoutes.length) {
					next();
				} else {
					store.commit('permission/SET_AGENT_ROUTES');
					agentRoutes.forEach(route => {
						router.addRoute(route);
					})
					next({
						...to,
						replace: true
					})
				}
			}
		} else if (login_type === 'sys_agent') { //代理商端
			if (to.path === '/login' || to.path === '/') {
				next('/merchants');
			} else {
				if (store.state.permission.addRoutes.length) {
					next();
				} else {
					store.commit('permission/SET_MERCHANTS_ROUTES');
					merchantsRoutes.forEach(route => {
						router.addRoute(route);
					})
					next({
						...to,
						replace: true
					})
				}
			}
		} else {
			if (!store.state.setting.corp_id && typeof store.state.setting.corp_id !== 'object') {
				await store.dispatch('setting/getWxkConfig');
			}
			if (store.state.setting.corp_id) { //判断当前是否存在已配置企业
				if (store.state.permission.addRoutes.length) {
					if (to.path === '/login' || to.path === '/') { // 企业微信端
						const path = await store.dispatch('permission/getIndexPath');
						next(path);
					} else {
						next();
					}
				} else {
					const addRoutes = await store.dispatch('permission/getRoutes');
					addRoutes.forEach(route => {
						router.addRoute(route);
					});
					next({
						...to,
						replace: true
					})
				}
			} else {
				if (store.state.permission.addRoutes.length) {
					if (to.path.includes('/company_setting')) {
						next();
						NProgress.done();
					} else {
						if (from.path !== '/company_setting/index' || to.path.includes(
								'/company_setting')) {
							next('/company_setting/index');
						} else {
							MessageBox.alert('请配置当前企业或选择已配置企业后进行操作', '提示', {
								confirmButtonText: '确定',
								callback: action => {
									Vue.nextTick(() => {
										var oDoms = document.querySelectorAll(
											'.el-menu-item');
										[...oDoms].forEach(ele => {
											if (ele.dataset.path ==
												'/company_setting/index') {
												ele.classList.add(
													'is-active');
											} else {
												ele.classList.remove(
													'is-active');
											}
										})
									})
								}
							});
						}
						NProgress.done();
					}
				} else {
					const addRoutes = await store.dispatch('permission/getRoutes');
					addRoutes.forEach(route => {
						router.addRoute(route);
					});
					next({
						...to,
						replace: true
					})
				}
			}
		}

	} else {
		if (to.path === '/') {
			next('/login');
			NProgress.done();
		} else {
			if (whiteList.indexOf(to.path) !== -1) {
				next();
			} else {
				next('/login');
			}
		}
	}
});

router.afterEach((to, from) => {
	// ...
	NProgress.done();
})
