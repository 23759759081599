import axios from "axios";

import router from "@/router";

import qs from 'qs';

import md5 from 'js-md5';

import {
	Loading,
	Message
} from "element-ui";



import store from '@/store';

// const numberOfAjaxCAllPending = 0; // 待处理请求个数
const cancelTokens = [];
const _axios = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL
})

// _axios.defaults.timeout = 10 * 1000;

_axios.interceptors.request.use((config) => {
	// numberOfAjaxCAllPending++;
	let user_id, token;
	try {
		user_id = JSON.parse(localStorage.getItem('huankemao_user_info')).user_id;
		token = JSON.parse(localStorage.getItem('huankemao_user_info')).token;
	} catch (e) {
		//TODO handle the exception
		router.replace('/login');
		return false;
	}
	const time = parseInt(new Date().getTime() / 1000);
	const sign = md5(token + time + md5('huankemao' + config.url + (qs.stringify(config.data) ? qs.stringify(
		config.data) : '{}')));
	config.headers['Auth'] = JSON.stringify({
		token,
		user_id,
		time,
		sign
	});

	if (config.data instanceof Blob || config.data instanceof FormData) {
		console.log('上传文件', config.data)
	} else {
		if (config.params) {
			console.log('URL参数', config.params)
		}
		if (config.data) {
			config.data = qs.stringify(config.data);
			console.log('Body参数', config.data)
		}
	}

	if (config['source']) {
		cancelTokens.push(config['source'])
	} else {
		const source = axios.CancelToken.source()
		config.source = source
		config.cancelToken = source.token
		cancelTokens.push(source)
	}
	return config
})

_axios.interceptors.response.use((response) => {
		// numberOfAjaxCAllPending--;
		// console.log("------------  Ajax pending", numberOfAjaxCAllPending);

		// if (numberOfAjaxCAllPending == 0) {
		// 	//hide loader
		// }
		const config = response.config;
		const data = response.data;
		if (data instanceof Blob) {
			return data
		}
		switch (parseInt(data.code)) {
			case 200:
				return data;
			case 501:
			case 504:
				localStorage.removeItem('huankemao_user_info');
				Message.error(data.msg);
				router.push('/login');
				return Promise.reject();
			default:
				Message.error(data.msg);
				return Promise.reject();
		}
	},
	(error) => {
		console.log(error);
		Message.error('未知异常');
		return Promise.reject(error);
	}
)

/**
 * 取消所有请求
 */
export const abortAll = () => {
	for (let i = 0; i < cancelTokens.length; i++) {
		cancelTokens[i].cancel()
	}
	cancelTokens.splice(0);
}

/**
 * 网络请求公共方法
 *
 * @param config
 * showLoading 是否显示加载弹窗(默认true)
 * loadingText 加载弹窗文字(默认‘加载中’)
 * toastError 是否显示错误提示(默认true)
 */
export const _request = (config) => {
	config = {
		toastError: true,
		showLoading: true,
		loadingText: '加载中',
		...config
	}
	config.data.corp_id = store.state.setting.corp_id;
	const showLoading = config.showLoading

	let timer = null
	let loading = null

	if (showLoading) {
		timer = setTimeout(() => {
			loading = Loading.service({
				lock: true,
				text: config.loadingText,
				background: 'rgba(0, 0, 0, 0.7)'
			});
		}, 500);
	}
	return new Promise((resolve, reject) => {
		_axios.request(config)
			.then((res) => {
				resolve(res)
			})
			.catch((err) => {
				reject(err)
			})
			.finally(() => {
				if (timer) {
					clearTimeout(timer)
				}
				if (loading) {
					loading.close();
				}
			})
	})
}

export const _get = (url, config = {}) =>
	_request({
		method: 'get',
		url: url,
		...config,
	})

export const _delete = (url, config = {}) =>
	_request({
		method: 'delete',
		url: url,
		...config,
	})

export const _head = (url, config = {}) =>
	_request({
		method: 'head',
		url: url,
		...config,
	})

export const _options = (url, config = {}) =>
	_request({
		method: 'options',
		url: url,
		...config,
	})

export const _post = (url, data = {}, config = {}) =>
	_request({
		method: 'post',
		url: url,
		data: data,
		...config,
	})

export const _put = (url, data = {}, config = {}) =>
	_request({
		method: 'put',
		url: url,
		data: data,
		...config,
	})

export const _patch = (url, data = {}, config = {}) =>
	_request({
		method: 'patch',
		url: url,
		data: data,
		...config,
	})

export const _upload = (url, params, progress, config = {}) => {
	config = {
		toastError: true,
		showLoading: true,
		stream: false,
		...config
	}
	const showLoading = config.showLoading;
	let loading = null;
	let data = null;
	if (config.stream) {
		data = new Blob([path])
	} else {
		data = new FormData();
		for (let key in params) {
			data.append(key, params[key]);
		}
	}

	return new Promise((resolve, reject) => {
		// noinspection JSUnusedGlobalSymbols
		_axios.request({
				method: 'post',
				url: url,
				data: data,
				onUploadProgress: (res) => {
					const total = res.total
					const loaded = res.loaded
					const percent = ((loaded * 100) / total).toFixed(2)
					console.log('上传进度', percent)
					if (progress && (typeof progress === 'function')) {
						console.log(progress)
						progress(percent)
					}
					// if (showLoading) {
					// 	loading = Loading.service({
					// 		lock: true,
					// 		text: config.loadingText,
					// 		background: 'rgba(0, 0, 0, 0.7)'
					// 	});
					// }
				},
				...config
			})
			.then((res) => {
				resolve(res)
			})
			.catch((err) => {
				reject(err)
			})
			.finally(() => {
				// if (loading) {
				// 	loading.close();
				// }
			})
	})
}

export const _download = (url, config, method = 'post') => {
	config = {
		url: url,
		method,
		fileName: '',
		toastError: true,
		showLoading: true,
		responseType: 'blob',
		...config
	}
	const showLoading = config.showLoading

	let loading = null

	if (showLoading) {
		loading = Loading.service({
			lock: true,
			text: config.loadingText,
			background: 'rgba(0, 0, 0, 0.7)'
		});
	}

	_axios.request(config).then(blob => {
		const a = document.createElement('a')
		const url = window.URL.createObjectURL(blob)
		a.href = url
		a.download = config.fileName
		a.click()
		window.URL.revokeObjectURL(url)
	}).catch(err => {
		if (config.toastError) {
			Message.error(err)
		}
	}).finally(() => {
		if (loading) {
			loading.close();
		}
	})
}
