/** @file:封装axios文件 */
import Qs from 'qs';
import md5 from 'js-md5';
import Vue from '../main';
import {
	customMessage
} from '@/util/utils';
import axios from 'axios';
import store from '@/store';
import router from '../router/index';
import {
	getToken,
	removeToken
} from '@/util/auth.js';
const instance = axios.create({});
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 添加请求拦截器
instance.interceptors.request.use((config) => {
	let {
		user_id,
		token,
	} = JSON.parse(localStorage.getItem('huankemao_user_info'));
	if (getToken() && token && getToken() === token) {
		let time = parseInt(new Date().getTime() / 1000);
		config.data.corp_id = store.state.setting.corp_id;
		let sign = md5(token + time + md5('huankemao' + config.url.substr(0, config.url.length) + Qs
			.stringify(config.data)));
		let obj = {
			user_id,
			token,
			time,
			sign
		}
		config.data = Qs.stringify(config.data);
		config.headers['Auth'] = JSON.stringify(obj);
		return config;
	} else {
		customMessage.error({
			message: '登录过期，请重新登录'
		})
		removeToken();
		localStorage.removeItem('huankemao_user_info');
		location.reload();
		return new Promise(() => {})
	}
}, function(error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});
// 添加响应拦截器
instance.interceptors.response.use((response) => {
	if (response.config.url.includes('/update_customer_tag')) return false;
	switch (response.data.code) {
		case 200:
			return response.data;
		case 500:
			if (response.data.msg == '用户不存在') {
				localStorage.removeItem('huankemao_user_info');
				location.reload();
				return new Promise(() => {});
			} else {
				customMessage.error({
					message: response.data.msg
				})
				return response.data;
			}
			case 501:
				localStorage.removeItem('huankemao_user_info');
				customMessage.error({
					message: response.data.msg
				});
				location.reload();
				return response.data;
			case 502:
			case 503:
				customMessage.error({
					message: response.data.msg
				})
				return response.data;
			case 504:
				customMessage.error({
					message: response.data.msg
				})
				localStorage.removeItem('huankemao_user_info');
				location.reload();
				return new Promise(() => {});
	}
}, (err) => {
	return Promise.reject(err);
});

export default instance;
